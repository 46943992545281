<template>
  <div>
    <div class="d-flex justify-content-between mt-65 mb-45">
      <div>
        <h2 class="text-primary">
          Key statistics
        </h2>
        <p> More than one statistic can be added by clicking on this button </p>
      </div>
      <div>
        <button class="btn btn-add" type="submit" @click="addMoreKeyStatistics"> +Add</button>
      </div>
    </div>
    <div class="bg-white py-40">
      <div class="row p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <!-- Key Statistics Item start -->
          <div v-for="(_kStats, _index) in keyStatistics" :key="_index" class="form-group mb-30">
            <div class="form-row">
              <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                <label class="col-form-label">Key statistic Detail</label>
              </div>
              <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                <div class="d-block">
                  <div class="form-group">
                    <div class="drop drop-inline mr-30 drop-sm">
                      <div class="cont">
                        <img
                          v-if="_kStats.icon && _kStats.icon.previewUrl"
                          :src="_kStats.icon.previewUrl" alt="Icon" class="icon-preview"/>
                        <img
                          v-else-if="_kStats.icon && photoPreviewMapping[_kStats.icon.tsync_id]"
                          :src="photoPreviewMapping[_kStats.icon.tsync_id]" alt="Icon" class="icon-preview"/>
                        <img
                          v-else-if="_kStats.icon && _kStats.icon.relative_url"
                          :src="_kStats.icon.relative_url | makeAbsUrl" alt="Icon"
                          class="icon-preview"/>
                        <img
                          v-else alt="Icon" src="@/assets/img/icons/upload1.svg">
                        <div v-if="!_kStats.icon || !_kStats.icon.name" class="desc"> Upload Icon</div>
                        <div v-if="_kStats.icon && _kStats.icon.name" class="desc">
                          {{ _kStats.icon.name | textTruncate(15) }}
                        </div>
                      </div>
                      <input type="file" @change="onIconChange($event,_index)">
                      <div
                        v-if="$v.$error & !$v.keyStatistics.$each.$iter[_index].icon.tsync_id.required"
                        class="error">
                        Icon is required.
                      </div>
                    </div>
                    <div class="d-inline-flex float-right">
                      <button
                        v-if="keyStatistics.length > 1"
                        class="btn btn-add-success float-right"
                        @click="removeFromChildForm(keyStatistics,_index, 'api', keyStatisticsContentsState)">
                        <i aria-hidden="true" class="fa fa-times"></i> Close
                      </button>
                    </div>
                    <div v-if="_kStats.icon.name" class="d-inline-flex">
                      <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                         @click="clearIcon(keyStatistics,_index)"> Clear</i>
                    </div>
                  </div>
                  <div class="form-group">
                    <input v-model="_kStats.subtitle" class="form-control admin-input"
                           placeholder="Value &amp; unit" type="text">
                    <div
                      v-if="$v.$error & !$v.keyStatistics.$each.$iter[_index].subtitle.required"
                      class="error">
                      Value & unit is required.
                    </div>
                  </div>
                  <div class="form-group">
                    <input v-model="_kStats.title" class="form-control admin-input"
                           placeholder="Avg. coffee farm size"
                           type="text"></div>
                  <div
                    v-if="$v.$error & !$v.keyStatistics.$each.$iter[_index].title.required"
                    class="error">
                    Title is required.
                  </div>
                  <div class="form-group">
                    <input v-model="_kStats.order" class="form-control admin-input"
                           placeholder="Type order"
                           type="text"></div>
                  <div
                    v-if="$v.$error & !$v.keyStatistics.$each.$iter[_index].order.required"
                    class="error">
                    Order is required.
                  </div>
                  <div
                    v-if="$v.$error & !$v.keyStatistics.$each.$iter[_index].order.numeric"
                    class="error">
                    Only positive number is acceptable.
                  </div>
                </div>
                <!-- Key Statistics Item Start -->
              </div>
            </div>
          </div>
          <!-- Key Statistics Item end -->
          <div class="form-group mb-30">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                <div class="text-right mt-30">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                            @click="saveKeyStatistics">
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { numeric, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import UtilityMixin from '@/mixins/utility-mixin'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'

export default {
  name: 'EachCountryKeyStatistics',
  mixins: [FormMixin, validationMixin, UtilityMixin],
  data () {
    return {
      keyStatistics: [
        {
          icon: {
            previewUrl: null,
            file: null,
            name: null,
            tsync_id: null
          },
          subtitle: '',
          title: '',
          order: 0
        }
      ],
      photoPreviewMapping: {},
      photoTsyncIdMapping: {}
    }
  },
  props: {
    country: {
      type: Object,
      required: true,
      default: function () {
        return {
          code: '',
          name: ''
        }
      }
    }
  },
  validations: {
    keyStatistics: {
      required,
      $each: {
        order: {
          required,
          numeric
        },
        title: {
          required
        },
        subtitle: {
          required
        },
        icon: {
          tsync_id: { required }
        }
      }
    }
  },
  computed: {
    keyStatisticsContentsState () {
      return this.$store.state.EnablingFarmer.enablingFarmersContents
    },
    keyStatisticsContents () {
      return this.$store.getters.getEnablingFarmersCountryKeyStatistics(this.country.name)
    }
  },
  methods: {
    addMoreKeyStatistics () {
      this.keyStatistics.push({
        icon: {
          previewUrl: null,
          file: null,
          name: null,
          tsync_id: null
        },
        subtitle: '',
        title: '',
        order: 1
      })
    },
    onIconChange (e, index) {
      // index => key statistics array objects index
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.keyStatistics[index].icon.previewUrl = URL.createObjectURL(file)
        this.keyStatistics[index].icon.file = file
        this.keyStatistics[index].icon.name = file.name
        this.keyStatistics[index].icon.tsync_id = this.uuidV4()
      } else {
        // if icon upload widget clicked and no image selected
        this.keyStatistics[index].icon.previewUrl = null
        this.keyStatistics[index].icon.file = null
        this.keyStatistics[index].icon.name = null
        this.keyStatistics[index].icon.tsync_id = null
      }
    },
    async saveKeyStatistics () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const keyStatsPromises = []
          this.keyStatistics.map((item) => {
            const response = this.submitEachKeyStatistics(item)
            keyStatsPromises.push(response)
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(keyStatsPromises).then((values) => {
            const photoPromises = []
            values.map((item) => {
              const iconId = item.data.icon ? item.data.icon.id : 0
              const iconTsyncId = item.data.icon ? item.data.icon.tsync_id : ''
              if (iconId === 0 || iconTsyncId === '') return
              const photoFile = this.photoTsyncIdMapping[iconTsyncId]
              if (!photoFile) return
              const response = this.uploadMultipartPhoto(iconId, photoFile)
              photoPromises.push(response)
            })
            Promise.all(photoPromises).then((iconResponses) => {
              this.$notify({
                title: `${this.country.name}: Key Statistics`,
                text: 'Key statistics updated successfully.',
                type: 'success', // 'warn', 'error', 'success'
                duration: 5000
              })
              this.$forceUpdate()
            })
          })
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachKeyStatistics (data) {
      const formData = this.$_.cloneDeep(data)
      const iconTsyncId = (formData.icon && formData.icon.tsync_id) ? formData.icon.tsync_id : this.uuidV4()
      this.photoTsyncIdMapping[iconTsyncId] = data.icon.file
      if (data.icon && data.icon.previewUrl) {
        this.photoPreviewMapping[iconTsyncId] = data.icon.previewUrl
      }
      if (!formData.id) {
        formData.icon = {
          tsync_id: iconTsyncId,
          extension: formData.icon.name.split('.').pop()
        }
      } else {
        try {
          formData.icon.extension = formData.icon.name.split('.').pop()
        } catch (e) {

        }
      }
      formData.country = this.country.id
      formData.content = this.$store.getters.getContentByAppModule(Menus.enablingFarmers.key).id
      formData.content_type = ContentTypes.MINI_CARD
      formData.parent = this.$store.getters.getParentContentItemByContentType(
        ContentTypes.KEY_STATISTICS, Menus.enablingFarmers.key).id
      return await this.$store.dispatch('createOrUpdateEnablingFarmersContent', formData)
    }
  },
  watch: {
    keyStatisticsContents: {
      deep: true,
      handler (newValue) {
        if (newValue.length > 0) {
          this.keyStatistics = _.cloneDeep(newValue)
        } else {
          this.keyStatistics = [{
            icon: {
              previewUrl: null,
              file: null,
              name: null,
              tsync_id: null
            },
            title: '',
            subtitle: '',
            order: 0
          }]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-preview {
  height: 42px;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
