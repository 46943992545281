<template>
  <div>
    <figure class="highcharts-figure p-0">
      <div id="countries">
        <Chart :options="getChartConfig()"/>
      </div>
    </figure>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

export default {
  name: 'ChartReport',
  components: {
    Chart
  },
  props: {
    chartContent: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  methods: {
    getChartConfig () {
      return {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: this.chartContent.categories,
          crosshair: true,
          title: {
            text: this.chartContent.chart_horizontal_data
          }
        },
        yAxis: {
          min: 0,
          // max: 8000,
          tickInterval: 1000,
          title: {
            text: this.chartContent.chart_vertical_data,
            margin: 100
          },
          labels: {
            formatter: function () {
              return this.value
            }
          }
        },
        navigation: {
          buttonOptions: {
            enabled: false
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 14px; font-family:AptiferLight">{point.key}</span><br/>',
          pointFormat: '<span style="color:{point.color}">●</span> <span style="font-family:AptiferLight">{series.name}: {point.y}</span><br/>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 26
          },
          series: {
            stacking: 'normal'
          }
          // column: {
          //   pointPadding: 0.2,
          //   borderWidth: 0
          // },
          // series: {
          //   pointWidth: 26
          // }
        },
        credits: {
          enabled: false
        },
        series: this.chartContent.series
      }
    }
  }
}
</script>

<style scoped>

</style>
