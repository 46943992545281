<template>
  <div>
    <div class="bg-white py-40 mb-25">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="form-group">
            <div class="form-row">
              <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                <label class="col-form-label">Select Country</label>
              </div>
              <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                <select v-model="country" class="form-control admin-input">
                  <option :value="{}">Select Countries</option>
                  <option v-for="(country, _index) in countries" :key="_index"
                          :value="{...country}">
                    {{ country.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EachCountryGreetings v-show="country.name" :country="country"/>
    <EachCountryKeyStatistics v-show="country.name" :country="country"/>
    <EachCountryKeyServiceGreetings v-show="country.name" :country="country"/>
    <EachCountryKeyServiceForm v-show="country.name" :country="country"/>
    <EachCountryFurtherResource v-show="country.name" :country="country"/>
    <EachCountryFarmerStories v-show="country.name" :country="country" :regions="regionMetas"/>
  </div>
</template>

<script>
import EachCountryFurtherResource from '@/components/admin/enabling-farmres/each-country/EachCountryFurtherResource'
import EachCountryKeyServiceForm from '@/components/admin/enabling-farmres/each-country/EachCountryKeyServiceForm'
import EachCountryKeyStatistics from '@/components/admin/enabling-farmres/each-country/EachCountryKeyStatistics'
import EachCountryGreetings from '@/components/admin/enabling-farmres/each-country/EachCountryGreetings'
import EachCountryKeyServiceGreetings
  from '@/components/admin/enabling-farmres/each-country/EachCountryKeyServiceGreetings'
import _ from 'lodash'
import EachCountryFarmerStories from '@/components/admin/enabling-farmres/each-country/EachCountryFarmerStories'
import CommonUtils from '@/utils/common'
import APIHelper from '@/service/api-helper'
import ServerConfig from '@/config/server'

export default {
  name: 'EachCountry',
  components: {
    EachCountryKeyStatistics,
    EachCountryKeyServiceForm,
    EachCountryFurtherResource,
    EachCountryGreetings,
    EachCountryKeyServiceGreetings,
    EachCountryFarmerStories
  },
  data () {
    return {
      country: {
        name: '',
        code: ''
      },
      regionMetas: []
    }
  },
  computed: {
    countries () {
      return _.sortBy(this.$store.getters.getNkgCountry, 'name')
    }
  },
  methods: {
    async get () {
      const response = await APIHelper.getData(this.apiUrl)
      const data = response.data
      if (data.success) {
        const totalPage = _.get(data, 'total_page')
        const completedPercentage = Math.ceil(this.mapLoadingPercentage + (100 / totalPage))
        if (completedPercentage > 100) {
          this.mapLoadingPercentage = 100
        } else {
          this.mapLoadingPercentage = completedPercentage
        }
        this.data = [...this.data, ...data.results]
        if (data.next !== null) {
          this.apiUrl = ServerConfig.serverUrl + data.next
          return this.get()
        }
      } else {
        this.errors.push(data)
      }
    },
    async getRegions () {
      const vm = this
      vm.limit = 100
      vm.offset = 0 // For the first time
      const params = {
        ...{ search: 1 },
        ...{
          limit: vm.limit,
          offset: vm.offset
        }
      }
      vm.apiUrl = CommonUtils.makeApiUrl('v2/region-meta', params)
      vm.data = []
      vm.errors = []

      // eslint-disable-next-line no-async-promise-executor
      this.regionMetas = await new Promise(async (resolve, reject) => {
        await this.get()
        if (this.errors.length > 0) {
          reject(this.errors)
        }
        resolve(this.data)
      })
    }
  },
  mounted () {
    this.getRegions()
  }
}
</script>

<style scoped>

</style>
