<template>
  <div>
    <div class="d-flex justify-content-between mt-50 mb-45">
      <div>
        <h2 class="text-primary">
          Key Service
        </h2>
      </div>
    </div>
    <div class="bg-white py-40">
      <div class="row no-gutters p-20 p-lg-0">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="form-group mt-30">
            <div class="form-row">
              <div class="col-md-12 col-lg-4">
                <label class="col-form-label">Title</label>
              </div>
              <div class="col-md-12 col-lg-8">
                <input v-model="greetings.title"
                       class="admin-input form-control" placeholder="Your title"
                       type="text">
                <div
                  v-if="$v.$error & !$v.greetings.title.required"
                  class="error">
                  Title is required.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mt-30">
            <div class="form-row">
              <div class="col-md-12 col-lg-4">
                <label class="col-form-label">Description</label>
              </div>
              <div class="col-md-12 col-lg-8">
                  <textarea v-model="greetings.description" class="admin-input form-control"
                            placeholder="Type full description" rows="5"
                            type="text"></textarea>
                <div
                  v-if="$v.$error & !$v.greetings.description.required"
                  class="error">
                  Description is required.
                </div>
              </div>
            </div>
            <div class="form-row ">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                <div class="text-right mt-30">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                            @click="saveGreetings">
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import UtilityMixin from '@/mixins/utility-mixin'
import { required } from 'vuelidate/lib/validators'
import { ContentTypes } from '@/config/constants'
import Menus from '@/config/menus'

export default {
  name: 'EachCountryKeyServiceGreetings',
  mixins: [FormMixin, validationMixin, UtilityMixin],
  data () {
    return {
      greetings: {
        description: '',
        title: ''
      }
    }
  },
  props: {
    country: {
      type: Object,
      required: true,
      default: function () {
        return {
          code: '',
          name: ''
        }
      }
    }
  },
  computed: {
    greetingContent () {
      return this.$store.getters.getEnablingFarmersCountryKeyServicesGreetings(this.country.name)
    }
  },
  validations: {
    greetings: {
      title: {
        required
      },
      description: {
        required
      }
    }
  },
  methods: {
    async saveGreetings () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const _data = {
            ...this.greetings,
            content_type: ContentTypes.GREETING,
            country: this.country.id,
            parent: this.$store.getters.getParentContentItemByContentType(
              ContentTypes.KEY_SERVICE, Menus.enablingFarmers.key).id,
            content: this.$store.getters.getContentByAppModule(Menus.enablingFarmers.key).id
          }
          const response = await this.$store.dispatch('createOrUpdateEnablingFarmersContent', _data)
          if (response.data.success) {
            this.$notify({
              title: `Key Service: ${this.country.name}`,
              text: 'Title and Description updated successfully.',
              type: 'success', // 'warn', 'error', 'success'
              duration: 5000
            })
          }
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    }
  },
  watch: {
    greetingContent: {
      deep: true,
      handler (newValue) {
        if (!_.isEmpty(newValue)) {
          this.greetings = _.cloneDeep(newValue)
        } else {
          this.greetings = {
            description: '',
            title: ''
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea.form-control {
  padding-top: 15px;
}
</style>
