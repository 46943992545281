<template>
  <div>
    <div class="d-flex justify-content-between mt-65 mb-45">
      <div>
        <h2 class="text-primary">
          Farmer stories
        </h2>
        <p> More than one farmer stories can be added by clicking on this button </p>
      </div>
      <div>
        <button class="btn btn-add" type="submit" @click="addMoreFarmerStories"> +Add</button>
      </div>
    </div>
    <div class="bg-white py-40">
      <div class="row efsc-sec-10">
        <div class="col-lg-10 col-12 m-auto">
          <div v-for="(_resource, _index) in farmerStories" :key="_index" class="form-group mb-55">
            <div class="form-group mt-30">
              <div class="form-row">
                <div class="col-12 col-lg-4 col-sm-12">
                  <label class="col-form-label">Farmer name</label>
                </div>
                <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                  <input v-model="_resource.title" class="admin-input form-control" placeholder="Your title"
                         type="text"/>
                  <div
                    v-if="$v.$error & !$v.farmerStories.$each.$iter[_index].title.required"
                    class="error">
                    Farmer name is required.
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="d-inline-flex float-right">
                    <button
                      v-if="farmerStories.length > 0"
                      class="btn btn-add-success float-right"
                      @click="removeFromChildForm(farmerStories,_index, 'api', resourcesContentsState)">
                      <i aria-hidden="true" class="fa fa-times"></i> Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                  <label class="col-form-label">Region</label>
                </div>
                <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                  <select v-model="_resource.region_meta" class="form-control admin-input">
                    <option v-for="(region, _index) in regionMetas" :key="_index"
                            :value="{...region}">
                      {{ region.region_name }}
                    </option>
                  </select>
                  <div
                    v-if="$v.$error & !$v.farmerStories.$each.$iter[_index].region_meta.required"
                    class="error">
                    Region is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                  <label class="col-form-label">Size of coffee farm</label>
                </div>
                <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                  <input v-model="_resource.subtitle" class="admin-input form-control" placeholder="Size of coffee farm"
                         type="text">
                  <div
                    v-if="$v.$error & !$v.farmerStories.$each.$iter[_index].subtitle.required"
                    class="error">
                    Size of coffee farm is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                  <label class="col-form-label">Joined NKG Bloom</label>
                </div>
                <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                  <input v-model="_resource.date"
                         class="admin-input form-control"
                         name="name" placeholder="Joined NKG Bloom. ex. MM/YYYY"
                         type="text">
                  <div
                    v-if="$v.$error & !$v.farmerStories.$each.$iter[_index].date.required"
                    class="error">
                    Joined NKG Bloom is required.
                  </div>
                  <div
                    v-if="$v.$error & !$v.farmerStories.$each.$iter[_index].date.monthYearFormat"
                    class="error">
                    Date format should be MM/YYYY.
                  </div>
                </div>
              </div>
            </div>
            <div class="from-group mb-30">
              <div class="form-row">
                <div class="col-12 col-md-2 col-lg-4 col-sm-12">
                  <label class="col-form-label">Photo</label>
                </div>
                <div class="col-12 col-md-10 col-lg-8 col-sm-12 content-block">
                  <div class="drop drop-inline mr-30 drop-sm">
                    <div class="cont">
                      <img
                        v-if="_resource.photo && _resource.photo.previewUrl"
                        :src="_resource.photo && _resource.photo.previewUrl" alt="photo" class="photo-preview"/>
                      <img
                        v-else-if="_resource.photo && photoPreviewMapping[_resource.photo.tsync_id]"
                        :src="photoPreviewMapping[_resource.photo.tsync_id]" alt="photo" class="photo-preview"/>
                      <img
                        v-else-if="_resource.photo && !_resource.photo.previewUrl && !_resource.photo.relative_url"
                        alt="" src="@/assets/img/icons/upload1.svg">
                      <img
                        v-else :src="_resource.photo && _resource.photo.relative_url | makeAbsUrl" alt="photo"
                        class="photo-preview"/>
                      <div v-if="_resource.photo && !_resource.photo.name" class="desc"> Upload photo</div>
                      <div v-if="_resource.photo && _resource.photo.name" class="desc">
                        {{ _resource.photo.name | textTruncate(15) }}
                      </div>
                    </div>
                    <input
                      accept="image/*"
                      type="file"
                      @change="onPhotoChange($event,_index)"
                    >
                    <div
                      v-if="$v.$error & !$v.farmerStories.$each.$iter[_index].photo.tsync_id.required"
                      class="error">
                      Photo is required.
                    </div>
                  </div>
                  <div v-if="_resource.photo && _resource.photo.name" class="d-inline-flex">
                    <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                       @click="clearCoverImage(farmerStories,_index)"> Clear</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                  <label class="col-form-label">Farmer story</label>
                </div>
                <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                <textarea v-model="_resource.description" class="admin-input form-control"
                          placeholder="Type farmer story" rows="5"
                          type="text"></textarea>
                  <div
                    v-if="$v.$error & !$v.farmerStories.$each.$iter[_index].description.required"
                    class="error">
                    Farmer story is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-30">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                <div class="text-right mt-30">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                            @click="saveFarmerStories">
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import UtilityMixin from '@/mixins/utility-mixin'
import { required } from 'vuelidate/lib/validators'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'
import moment from 'moment'
import _ from 'lodash'

const monthYearFormat = (value) => {
  const regex = /^(\d{1,2})\/(\d{4})$/
  return regex.test(value)
}

export default {
  name: 'EachCountryFarmerStories',
  data () {
    return {
      farmerStories: [
        {
          title: '',
          subtitle: '',
          region_meta: {}, // used for region
          description: '',
          date: null,
          photo: {
            previewUrl: null,
            file: null,
            name: null,
            tsync_id: null
          }
        }
      ],
      photoTsyncIdMapping: {},
      photoPreviewMapping: {}
    }
  },
  props: {
    country: {
      type: Object,
      required: true,
      default: function () {
        return {
          code: '',
          name: ''
        }
      }
    },
    regions: {
      type: Array,
      required: true,
      default: function () {
        return {
          code: '',
          name: ''
        }
      }
    }
  },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  validations: {
    farmerStories: {
      required,
      $each: {
        title: {
          required
        },
        subtitle: {
          required
        },
        description: {
          required
        },
        date: {
          required
        },
        region_meta: {
          required
        }, // used for region
        photo: {
          tsync_id: { required }
        }
      }
    }
  },
  computed: {
    resourcesContentsState () {
      return this.$store.state.EnablingFarmer.enablingFarmersContents
    },
    farmerStoriesContents () {
      return this.$store.getters.getEnablingFarmerCountryFarmerStories(this.country.name)
    },
    regionMetas () {
      const vm = this
      const _content = vm.regions.filter((item) => {
        return item.country !== null && item.country.public_code === vm.country.public_code
      })
      return _content || {}
    }
  },
  methods: {
    addMoreFarmerStories () {
      this.farmerStories.push({
        title: '',
        subtitle: '',
        region_meta: {}, // used for region
        description: '',
        date: null,
        photo: {
          previewUrl: null,
          file: null,
          name: null,
          tsync_id: null
        }
      })
    },
    onPhotoChange (e, index) {
      // index => key statistics array objects index
      // if (!this.farmerStories[index].photo) {
      //   this.farmerStories[index].photo = {}
      // }
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.farmerStories[index].photo.previewUrl = URL.createObjectURL(file)
        this.farmerStories[index].photo.file = file
        this.farmerStories[index].photo.name = file.name
        this.farmerStories[index].photo.tsync_id = this.uuidV4()
      } else {
        // if photo upload widget clicked and no image selected
        this.farmerStories[index].photo.previewUrl = null
        this.farmerStories[index].photo.file = null
        this.farmerStories[index].photo.name = null
        this.farmerStories[index].photo.tsync_id = null
      }
    },
    async saveFarmerStories () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const vm = this
          this.photoTsyncIdMapping = {}
          this.photoPreviewMapping = {}

          const farmerStoriesPromises = []
          const promises = await vm.farmerStories.reduce(async (memo, v) => {
            await memo
            farmerStoriesPromises.push(await vm.submitEachFarmerStories(v))
          }, [])
          _.unset(promises, '')
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(farmerStoriesPromises).then((values) => {
            const photoPromises = []
            values.map((item) => {
              const photoId = item.data.photo ? item.data.photo.id : 0
              const photoTsyncId = item.data.photo ? item.data.photo.tsync_id : ''
              if (photoId === 0 || photoTsyncId === '') return
              const photoFile = vm.photoTsyncIdMapping[photoTsyncId]
              if (_.get(photoFile, 'name')) {
                const response = vm.uploadMultipartPhoto(photoId, photoFile)
                photoPromises.push(response)
              }
            })
            Promise.all(photoPromises).then((photoResponses) => {
              this.$notify({
                title: 'Farmer stories',
                text: 'Farmer stories(s) updated successfully.',
                type: 'success', // 'warn', 'error', 'success'
                duration: 5000
              })
              // this.$forceUpdate()
            })
          })
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachFarmerStories (data) {
      const vm = this
      const formData = _.cloneDeep(data)
      const photoTsyncId = (formData.photo && formData.photo.tsync_id) ? formData.photo.tsync_id : vm.uuidV4()
      if (_.get(data, 'photo.file')) {
        vm.photoTsyncIdMapping[photoTsyncId] = data.photo.file
      }
      if (data.photo && data.photo.previewUrl) {
        vm.photoPreviewMapping[photoTsyncId] = data.photo.previewUrl
      }
      if (!formData.photo.id && formData.photo.name) {
        formData.photo = {
          tsync_id: photoTsyncId,
          extension: formData.photo.name.split('.').pop()
        }
      } else {
        try {
          formData.photo.extension = formData.photo.name.split('.').pop()
        } catch (e) {

        }
      }
      let _date = null
      try {
        _date = formData.date.split('/')[0] + '/01/' + formData.date.split('/')[1]
      } catch (e) {
        _date = null
      }
      if (_date) {
        formData.date = Date.parse(_date)
      }
      formData.country = vm.country.id
      formData.region_meta = formData.region_meta.id
      formData.content = vm.$store.getters.getContentByAppModule(Menus.enablingFarmers.key).id
      formData.content_type = ContentTypes.FARMER_STORIES
      // formData.parent = vm.$store.getters.getParentContentItemByContentType(
      //   ContentTypes.RESOURCE, Menus.enablingFarmers.key).id
      return await vm.$store.dispatch('createOrUpdateEnablingFarmersContent', formData)
    }
  },
  watch: {
    farmerStoriesContents: {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          const data = []
          _.cloneDeep(newValue).map((item) => {
            if (!_.get(item, 'photo.name')) {
              item.photo = {
                previewUrl: null,
                file: null,
                name: null,
                tsync_id: null
              }
            }
            const _date = item.date
            data.push({
              ...item,
              date: moment(_date).format('MM/YYYY')
            })
          })
          this.farmerStories = data
        } else {
          this.farmerStories = [
            {
              title: '',
              subtitle: '',
              region_meta: {}, // used for region
              description: '',
              date: null,
              photo: {
                previewUrl: null,
                file: null,
                name: null,
                tsync_id: null
              }
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea.form-control {
  padding-top: 15px;
}

.clear-file {
  cursor: pointer;
}

.photo-preview {
  height: 42px;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
