<template>
  <div class="bg-white py-40 mb-30">
    <div class="row no-gutters p-lg-0 p-20">
      <div class="col-lg-7 col-md-12 m-auto">
        <div class="form-group">
          <div class="form-row">
            <div class="col-12 col-md-12 col-lg-4 col-sm-12">
              <label class="col-form-label">Title</label>
            </div>
            <div class="col-12 col-md-12 col-lg-8 col-sm-12">
              <input
                v-model="greetings.title" class="admin-input form-control"
                placeholder="Your title" type="text">
              <div
                v-if="$v.$error & !$v.greetings.title.required"
                class="error">
                Title is required.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-row">
            <div class="col-12 col-md-12 col-lg-4 col-sm-12">
              <label class="col-form-label">Description</label>
            </div>
            <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                <textarea v-model="greetings.description" class="admin-input form-control"
                          placeholder="Type full description" rows="5"
                          type="text"></textarea>
              <div
                v-if="$v.$error & !$v.greetings.description.required"
                class="error">
                Title is required.
              </div>
            </div>
          </div>
          <div class="form-group mt-35">
            <div class="form-row">
              <div class="col-md-12 col-lg-4">
                <label class="col-form-label">Group
                  logo</label>
              </div>
              <div class="col-md-12 col-lg-8">
                <div class="drop drop-inline mr-30 drop-sm">
                  <div class="cont">
                    <img
                      v-if="greetings.photo && greetings.photo.previewUrl"
                      :src="greetings.photo.previewUrl" alt="Icon" class="icon-preview"/>
                    <img
                      v-else-if="greetings.photo && photoPreviewMapping[greetings.photo.tsync_id]"
                      :src="photoPreviewMapping[greetings.photo.tsync_id]" alt="Icon" class="icon-preview"/>
                    <img
                      v-else-if="greetings.photo && greetings.photo.relative_url"
                      :src="greetings.photo.relative_url | makeAbsUrl" alt="Icon"
                      class="icon-preview"/>
                    <img
                      v-else alt="Icon" src="@/assets/img/icons/upload1.svg">
                    <div v-if="!greetings.photo || !greetings.photo.name" class="desc"> Upload Photo</div>
                    <div v-if="greetings.photo && greetings.photo.name" class="desc">
                      {{ greetings.photo.name | textTruncate(15) }}
                    </div>
                  </div>
                  <input ref="imageFileUploader" accept="image/*" type="file" @change="onIconChange($event)"
                         @click="resetImageFileUploader">
                  <div
                    v-if="$v.$error & !$v.greetings.photo.tsync_id.required" class="error">
                    Icon is required.
                  </div>
                </div>
                <div v-if="greetings.photo && greetings.photo.name" class="d-inline-flex">
                  <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                     @click="clearPicture(greetings)"> Clear</i>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row ">
            <div class="col-md-12 m-auto">
              <div class="text-right mt-30">
                <div class="d-inline-flex">
                  <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                          @click="saveGreetings">
                    {{ saveButtonTxt }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { ContentTypes } from '@/config/constants'
import Menus from '@/config/menus'
import UtilityMixin from '@/mixins/utility-mixin'

export default {
  name: 'EachCountryGreetings',
  mixins: [FormMixin, validationMixin, UtilityMixin],
  data () {
    return {
      greetings: {
        photo: {
          previewUrl: null,
          file: null,
          name: null,
          tsync_id: null
        },
        description: '',
        title: ''
      },
      photoPreviewMapping: {},
      photoTsyncIdMapping: {}
    }
  },
  props: {
    country: {
      type: Object,
      required: true,
      default: function () {
        return {
          public_code: '',
          name: ''
        }
      }
    }
  },
  validations: {
    greetings: {
      photo: {
        tsync_id: { required }
      },
      title: {
        required
      },
      description: {
        required
      }
    }
  },
  computed: {
    greetingContent () {
      return this.$store.getters.getEnablingFarmersCountryGreetingContent(this.country.name)
    }
  },
  methods: {
    onIconChange (e) {
      try {
        // index => key statistics array objects index
        if (!this.greetings.photo) {
          this.greetings.photo = {}
        }
        if (e.target.files.length > 0) {
          const file = e.target.files[0]
          this.greetings.photo.previewUrl = URL.createObjectURL(file)
          this.greetings.photo.file = file
          this.greetings.photo.name = file.name
          this.greetings.photo.tsync_id = this.uuidV4()
        } else {
          // if photo upload widget clicked and no image selected
          this.greetings.photo.previewUrl = null
          this.greetings.photo.file = null
          this.greetings.photo.name = null
          this.greetings.photo.tsync_id = null
        }
      } catch (e) {
        this.errorLog(e)
      }
    },
    clearPicture (object) {
      object.photo.previewUrl = null
      object.photo.file = null
      object.photo.name = null
      object.photo.tsync_id = null
      object.photo.relative_url = null
    },
    async saveGreetings () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const vm = this
          this.photoTsyncIdMapping = {}
          this.photoPreviewMapping = {}
          this.fileTsyncIdMapping = {}
          const _contentItemPromises = []
          const response = vm.submitEachData(vm.greetings)
          _contentItemPromises.push(response)
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(_contentItemPromises).then((values) => {
            const photoPromises = []
            values.map((item) => {
              const iconId = item.data.photo ? item.data.photo.id : 0
              const iconTsyncId = item.data.photo ? item.data.photo.tsync_id : ''
              if (iconId === 0 || iconTsyncId === '') return
              const photoFile = vm.photoTsyncIdMapping[iconTsyncId]
              if (_.get(photoFile, 'name')) {
                const response = vm.uploadMultipartPhoto(iconId, photoFile)
                photoPromises.push(response)
              }
            })
            Promise.all(photoPromises).then((iconResponses) => {
              vm.$notify({
                title: vm.country.name,
                text: 'Title and Description updated successfully.',
                type: 'success', // 'warn', 'error', 'success'
                duration: 5000
              })
              // vm.$forceUpdate()
            })
          })
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachData (data) {
      const vm = this
      const formData = _.cloneDeep(data)
      const iconTsyncId = (formData.photo && formData.photo.tsync_id) ? formData.photo.tsync_id : vm.uuidV4()
      if (_.get(data, 'photo.file')) {
        vm.photoTsyncIdMapping[iconTsyncId] = data.photo.file
      }
      if (data.photo && data.photo.previewUrl) {
        vm.photoPreviewMapping[iconTsyncId] = data.photo.previewUrl
      }
      if (!formData.photo.id && formData.photo.name) {
        formData.photo = {
          tsync_id: iconTsyncId,
          extension: formData.photo.name.split('.').pop()
        }
      } else {
        try {
          formData.photo.extension = formData.photo.name.split('.').pop()
        } catch (e) {

        }
      }

      formData.content = vm.$store.getters.getContentByAppModule(Menus.enablingFarmers.key).id
      formData.content_type = ContentTypes.GREETING
      formData.country = vm.country.id
      return await vm.$store.dispatch('createOrUpdateEnablingFarmersContent', formData)
    }
  },
  watch: {
    greetingContent: {
      deep: true,
      handler (newValue) {
        if (!_.isEmpty(newValue)) {
          this.greetings = _.cloneDeep(newValue)
        } else {
          this.greetings = {
            photo: {
              previewUrl: null,
              file: null,
              name: null,
              tsync_id: null
            },
            description: '',
            title: ''
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea.form-control {
  padding-top: 15px;
}

.icon-preview {
  height: 42px;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
