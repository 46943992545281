<template>
  <div>
    <div class="row mt-75 mb-20">
      <div class="col-lg-4 col-md-12">
        <h3 class="text-primary">Further Resource on</h3></div>
      <div class="col-lg-8 col-md-12">
        <input v-model="furtherResourceGreetings.title" class="admin-input form-control"
               placeholder="Type tenet number & Country name"
               type="text">
        <div
          v-if="$v.$error & !$v.furtherResourceGreetings.title.required"
          class="error">
          Tenet number & Country name is required.
        </div>
      </div>
    </div>
    <div class="bg-primary-light en-dr-bg1 p-20">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="row no-gutters p-lg-0 p-20">
            <div class="col-md-8 ml-auto">
              <div class="d-flex justify-content-between align-items-center">
                <p class="w-50 m-0"> More than one resource can be added by clicking on this button </p>
                <button class="btn btn-add" type="submit" @click="addMoreFurtherResource"> + Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white py-40">
      <div class="row efsc-sec-10">
        <div class="col-lg-10 col-12 m-auto">
          <div v-for="(_resource, _index) in furtherResources" :key="_index" class="form-group mb-55">
            <div class="form-group mt-30">
              <div class="form-row">
                <div class="col-12 col-lg-4 col-sm-12">
                  <label class="col-form-label">Resource title</label>
                </div>
                <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                  <input v-model="_resource.title" class="admin-input form-control" placeholder="Your title"
                         type="text"/>
                  <div
                    v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].title.required"
                    class="error">
                    Title is required.
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="d-inline-flex float-right">
                    <button
                      v-if="furtherResources.length > 0"
                      class="btn btn-add-success float-right"
                      @click="removeFromChildForm(furtherResources,_index, 'api', resourcesContentsState)">
                      <i aria-hidden="true" class="fa fa-times"></i> Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                  <label class="col-form-label">File type</label>
                </div>
                <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                  <input v-model="_resource.subtitle" class="admin-input form-control" placeholder="File type"
                         type="text">
                  <div
                    v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].subtitle.required"
                    class="error">
                    Type is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                  <label class="col-form-label">Date</label>
                </div>
                <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                  <input v-model="_resource.date"
                         class="admin-input form-control"
                         name="name" placeholder="Date"
                         type="date">
                  <div
                    v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].date.required"
                    class="error">
                    Date is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-30">
              <div class="form-row">
                <div class="col-12 col-md-2 col-lg-4 col-sm-12">
                  <label class="col-form-label">Resource file</label>
                </div>
                <div class="col-12 col-md-10 col-lg-8 col-sm-12 content-block">
                  <div class="wrapper">
                    <div class="drop drop-inline drop-sm mr-30">
                      <div class="cont">
                        <img alt="" src="@/assets/img/icons/upload1.svg">
                        <div v-if="!_resource.file.tsync_id" class="desc"> Upload File</div>
                        <div v-if="_resource.file.tsync_id" class="desc">{{ _resource.file.name }}</div>
                      </div>
                      <input type="file" @change="onFileChange($event,_index)">
                    </div>
                    <div class="d-inline-flex">
                      <i v-show="_resource.file.tsync_id" aria-hidden="true"
                         class="fa fa-times ml-2 clear-file"
                         @click="clearFile(furtherResources, _index)"> Clear</i>
                    </div>
                    <p class="mt-10">
                      Or <br>
                      <input v-model="_resource.more_link" class="mt-10 admin-input form-control"
                             placeholder="Add URL"
                             type="text">
                    </p>
                    <div
                      v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].more_link.requiredUnless"
                      class="error">
                      Either resource file or more link is required.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="from-group mb-30">
              <div class="form-row">
                <div class="col-12 col-md-2 col-lg-4 col-sm-12">
                  <label class="col-form-label">Resource icon</label>
                </div>
                <div class="col-12 col-md-10 col-lg-8 col-sm-12 content-block">
                  <div class="drop drop-inline mr-30 drop-sm">
                    <div class="cont">
                      <img
                        v-if="_resource.icon && _resource.icon.previewUrl"
                        :src="_resource.icon && _resource.icon.previewUrl" alt="Icon" class="icon-preview"/>
                      <img
                        v-else-if="_resource.icon && photoPreviewMapping[_resource.icon.tsync_id]"
                        :src="photoPreviewMapping[_resource.icon.tsync_id]" alt="Icon" class="icon-preview"/>
                      <img
                        v-else-if="_resource.icon && !_resource.icon.previewUrl && !_resource.icon.relative_url"
                        alt="" src="@/assets/img/icons/upload1.svg">
                      <img
                        v-else :src="_resource.icon && _resource.icon.relative_url | makeAbsUrl" alt="Icon"
                        class="icon-preview"/>
                      <div v-if="_resource.icon && !_resource.icon.name" class="desc"> Upload Icon</div>
                      <div v-if="_resource.icon && _resource.icon.name" class="desc">
                        {{ _resource.icon.name | textTruncate(15) }}
                      </div>
                    </div>
                    <input
                      accept="image/*"
                      type="file"
                      @change="onIconChange($event,_index)"
                    >
                    <div
                      v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].icon.tsync_id.required"
                      class="error">
                      Icon is required.
                    </div>
                  </div>
                  <div v-if="_resource.icon && _resource.icon.name" class="d-inline-flex">
                    <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                       @click="clearIcon(furtherResources,_index)"> Clear</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-30">
              <div class="form-row">
                <div class="col-12 col-lg-4 col-sm-12">
                  <label class="col-form-label">Order</label>
                </div>
                <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                  <input v-model="_resource.order" class="admin-input form-control" placeholder="Type order"
                         type="text"/>
                  <div
                    v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].order.required"
                    class="error">
                    Order is required.
                  </div>
                  <div
                    v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].order.numeric"
                    class="error">
                    Only positive number is acceptable.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-30">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-10 col-sm-12">
                <div class="text-right mt-30">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                            @click="saveFurtherResource">
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import UtilityMixin from '@/mixins/utility-mixin'
import { numeric, required, requiredUnless } from 'vuelidate/lib/validators'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'EachCountryFurtherResource',
  data () {
    return {
      furtherResourceGreetings: {
        title: ''
      },
      furtherResources: [
        {
          title: '',
          subtitle: '',
          more_link: '',
          order: 0,
          date: null,
          icon: {
            previewUrl: null,
            file: null,
            name: null,
            tsync_id: null
          },
          file: {
            file: null,
            name: null,
            tsync_id: null
          }
        }
      ],
      photoTsyncIdMapping: {},
      photoPreviewMapping: {},
      fileTsyncIdMapping: {}
    }
  },
  props: {
    country: {
      type: Object,
      required: true,
      default: function () {
        return {
          code: '',
          name: ''
        }
      }
    }
  },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  validations: {
    furtherResourceGreetings: {
      title: { required }
    },
    furtherResources: {
      required,
      $each: {
        title: {
          required
        },
        subtitle: {
          required
        },
        date: {
          required
        },
        order: {
          required,
          numeric
        },
        icon: {
          tsync_id: {
            required
          }
        },
        more_link: {
          requiredUnless: requiredUnless(function (item) {
            return item.file.tsync_id !== null
          })
        },
        file: {
          requiredUnless: requiredUnless(function (item) {
            return item.more_link !== null
          })
        }
      }
    }
  },
  computed: {
    resourcesContentsState () {
      return this.$store.state.EnablingFarmer.enablingFarmersContents
    },
    furtherResourceContents () {
      return this.$store.getters.getEnablingFarmerCountryResourcesByYear(this.country.name)
    },
    furtherResourceContentsGreetingContent () {
      return this.$store.getters.getEnablingFarmerCountryResourceTitleByYear(this.country.name)
    }
  },
  methods: {
    addMoreFurtherResource () {
      this.furtherResources.push({
        title: '',
        subtitle: '',
        more_link: '',
        date: null,
        order: 0,
        icon: {
          previewUrl: null,
          file: null,
          name: null,
          tsync_id: null
        },
        file: {
          file: null,
          name: null,
          tsync_id: null
        }
      })
    },
    onIconChange (e, index) {
      // index => key statistics array objects index
      // if (!this.furtherResources[index].icon) {
      //   this.furtherResources[index].icon = {}
      // }
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.furtherResources[index].icon.previewUrl = URL.createObjectURL(file)
        this.furtherResources[index].icon.file = file
        this.furtherResources[index].icon.name = file.name
        this.furtherResources[index].icon.tsync_id = this.uuidV4()
      } else {
        // if icon upload widget clicked and no image selected
        this.furtherResources[index].icon.previewUrl = null
        this.furtherResources[index].icon.file = null
        this.furtherResources[index].icon.name = null
        this.furtherResources[index].icon.tsync_id = null
      }
    },
    onFileChange (e, index) {
      // if (!this.furtherResources[index].file) {
      //   this.furtherResources[index].file = {}
      // }
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.furtherResources[index].file.file = file
        this.furtherResources[index].file.name = file.name
        this.furtherResources[index].file.tsync_id = this.uuidV4()
      } else {
        this.furtherResources[index].file.file = null
        this.furtherResources[index].file.name = null
        this.furtherResources[index].file.tsync_id = null
      }
    },
    async saveFurtherResourceGreeting () {
      // Start: Further Resource Tenet Title
      const vm = this
      const _data = {
        title: vm.furtherResourceGreetings.title,
        content_type: ContentTypes.GREETING,
        country: vm.country.id,
        content: vm.$store.getters.getContentByAppModule(Menus.enablingFarmers.key).id,
        parent: vm.$store.getters.getParentContentItemByContentType(
          ContentTypes.RESOURCE, Menus.enablingFarmers.key
        ).id
      }
      const response = await vm.$store.dispatch('createOrUpdateEnablingFarmersContent', _data)
      if (response.data.success) {
        this.$notify({
          title: vm.country.name,
          text: 'Tenet No and Country created/ updated successfully.',
          type: 'success', // 'warn', 'error', 'success'
          duration: 5000
        })
      }
      // End: Further Resource Tenet Title
    },
    async saveFurtherResource () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const vm = this
          this.photoTsyncIdMapping = {}
          this.photoPreviewMapping = {}
          this.fileTsyncIdMapping = {}

          const keyStatsPromises = []
          const promises = await vm.furtherResources.reduce(async (memo, v) => {
            await memo
            keyStatsPromises.push(await vm.submitEachFurtherResource(v))
          }, [])
          _.unset(promises, '')
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(keyStatsPromises).then((values) => {
            const photoPromises = []
            const filePromises = []
            values.map((item) => {
              const iconId = item.data.icon ? item.data.icon.id : 0
              const iconTsyncId = item.data.icon ? item.data.icon.tsync_id : ''
              if (iconId === 0 || iconTsyncId === '') return
              const photoFile = vm.photoTsyncIdMapping[iconTsyncId]
              if (_.get(photoFile, 'name')) {
                const response = vm.uploadMultipartPhoto(iconId, photoFile)
                photoPromises.push(response)
              }

              // file upload
              const fileId = item.data.file ? item.data.file.id : 0
              const fileTsyncId = item.data.file ? item.data.file.tsync_id : ''
              if (fileId === 0 || fileTsyncId === '') return
              const file = this.fileTsyncIdMapping[fileTsyncId]
              if (_.get(file, 'file.name')) {
                const fileResponse = this.uploadFile(fileId, file.file)
                filePromises.push(fileResponse)
              }
            })
            Promise.all(photoPromises).then((iconResponses) => {
              this.$notify({
                title: 'Further Resources',
                text: 'Further Resource(s) updated successfully.',
                type: 'success', // 'warn', 'error', 'success'
                duration: 5000
              })
              // this.$forceUpdate()
            })
            Promise.all(filePromises).then((iconResponses) => {
              // this.$forceUpdate()
            })
          })
          await this.saveFurtherResourceGreeting()
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachFurtherResource (data) {
      const vm = this
      const formData = _.cloneDeep(data)
      const iconTsyncId = (formData.icon && formData.icon.tsync_id) ? formData.icon.tsync_id : vm.uuidV4()
      if (_.get(data, 'icon.file')) {
        vm.photoTsyncIdMapping[iconTsyncId] = data.icon.file
      }
      if (data.icon && data.icon.previewUrl) {
        vm.photoPreviewMapping[iconTsyncId] = data.icon.previewUrl
      }
      if (!formData.icon.id && formData.icon.name) {
        formData.icon = {
          tsync_id: iconTsyncId,
          extension: formData.icon.name.split('.').pop()
        }
      } else {
        try {
          formData.icon.extension = formData.icon.name.split('.').pop()
        } catch (e) {

        }
      }

      // File handling
      let fileSize = 0
      const fileTsyncId = (formData.file && formData.file.tsync_id) ? formData.file.tsync_id : vm.uuidV4()
      if (_.get(data, 'file.file')) {
        vm.fileTsyncIdMapping[fileTsyncId] = data.file
      }
      fileSize = _.get(data, 'file.file') ? _.get(data, 'file.file.size') : 0// uploaded file size in KB
      if (!formData.file.id && formData.file.name) {
        formData.file = {
          tsync_id: fileTsyncId,
          extension: formData.file.name.split('.').pop()
        }
      }
      if (!_.get(formData, 'file.tsync_id')) {
        delete formData.file
        formData.file = null
      }
      formData.date = Date.parse(formData.date)
      formData.description = fileSize
      formData.country = vm.country.id
      formData.content = vm.$store.getters.getContentByAppModule(Menus.enablingFarmers.key).id
      formData.content_type = ContentTypes.RESOURCE_MINI_CARD
      formData.parent = vm.$store.getters.getParentContentItemByContentType(
        ContentTypes.RESOURCE, Menus.enablingFarmers.key).id
      return await vm.$store.dispatch('createOrUpdateEnablingFarmersContent', formData)
    }
  },
  watch: {
    furtherResourceContents: {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          const data = []
          _.cloneDeep(newValue).map((item) => {
            if (!_.get(item, 'file.name')) {
              item.file = {
                file: null,
                name: null,
                tsync_id: null
              }
            }
            if (!_.get(item, 'icon.name')) {
              item.icon = {
                previewUrl: null,
                file: null,
                name: null,
                tsync_id: null
              }
            }
            const _date = item.date
            data.push({
              ...item,
              date: moment(_date).format('yyyy-MM-DD')
            })
          })
          this.furtherResources = data
        } else {
          this.furtherResources = [
            {
              title: '',
              subtitle: '',
              more_link: '',
              order: 0,
              date: null,
              icon: {
                previewUrl: null,
                file: null,
                name: null,
                tsync_id: null
              },
              file: {
                file: null,
                name: null,
                tsync_id: null
              }
            }
          ]
        }
      }
    },
    furtherResourceContentsGreetingContent: {
      deep: true,
      handler (newValue) {
        if (!_.isEmpty(newValue)) {
          this.furtherResourceGreetings = _.cloneDeep(newValue)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.clear-file {
  cursor: pointer;
}

.icon-preview {
  height: 42px;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
