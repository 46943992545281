<template>
  <EnablingFarmers></EnablingFarmers>
</template>

<script>
import EnablingFarmers from '@/components/admin/enabling-farmres/EnablingFarmers'

export default {
  name: 'EnablingFarmersView',
  components: { EnablingFarmers }
}
</script>

<style scoped>

</style>
