<template>
  <div>
    <div class="bg-primary-light en-dr-bg1 p-20">
      <div class="row no-gutters p-20 p-lg-0">
        <div class="col-md-5 m-auto">
          <div class="d-flex justify-content-between">
            <p class="w-50 m-0"> More than one service can be added by clicking on this button </p>
            <button class="btn btn-add" type="submit" @click="addMoreKeyServices"> + Add</button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white py-40">
      <div class="row no-gutters p-20 p-lg-0">
        <div class="col-lg-7 col-md-12 m-auto">
          <div v-for="(_kService, _index) in keyServices" :key="_index" class="form-group mt-30">
            <div class="form-row">
              <div class="col-md-12 col-lg-4">
                <label class="col-form-label">Key Service Detail</label>
              </div>
              <div class="col-md-12 col-lg-8">
                <div class="d-block">
                  <div class="form-group">
                    <div class="drop drop-inline mr-30 drop-sm">
                      <div class="cont">
                        <img
                          v-if="_kService.icon && _kService.icon.previewUrl"
                          :src="_kService.icon && _kService.icon.previewUrl" alt="Icon" class="icon-preview"/>
                        <img
                          v-else-if="_kService.icon && photoPreviewMapping[_kService.icon.tsync_id]"
                          :src="photoPreviewMapping[_kService.icon.tsync_id]" alt="Icon" class="icon-preview"/>
                        <img
                          v-else-if="_kService.icon && !_kService.icon.previewUrl && !_kService.icon.relative_url"
                          alt="" src="@/assets/img/icons/upload1.svg">
                        <img
                          v-else :src="_kService.icon && _kService.icon.relative_url | makeAbsUrl" alt="Icon"
                          class="icon-preview"/>
                        <div v-if="_kService.icon && !_kService.icon.name" class="desc"> Upload Icon</div>
                        <div v-if="_kService.icon && _kService.icon.name" class="desc">
                          {{ _kService.icon.name | textTruncate(15) }}
                        </div>
                      </div>
                      <input type="file" @change="onIconChange($event,_index)">
                      <div
                        v-if="$v.$error & !$v.keyServices.$each.$iter[_index].icon.tsync_id.required"
                        class="error">
                        Icon is required.
                      </div>
                    </div>
                    <div class="d-inline-flex float-right">
                      <button
                        v-if="keyServices.length > 1"
                        class="btn btn-add-success float-right"
                        @click="removeFromChildForm(keyServices, _index, 'api', keyServicesContentsState)">
                        <i aria-hidden="true" class="fa fa-times"></i> Close
                      </button>
                    </div>
                    <div v-if="_kService.icon && _kService.icon.name" class="d-inline-flex">
                      <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                         @click="clearIcon(keyServices,_index)"> Clear</i>
                    </div>
                  </div>
                  <div class="form-group">
                    <input v-model="_kService.subtitle" class="form-control admin-input"
                           placeholder="Type service name"
                           type="text"></div>
                  <div
                    v-if="$v.$error & !$v.keyServices.$each.$iter[_index].subtitle.required"
                    class="error">
                    Title is required.
                  </div>
                  <div class="form-group">
                    <input v-model="_kService.order" class="form-control admin-input"
                           placeholder="Type order"
                           type="text"></div>
                  <div
                    v-if="$v.$error & !$v.keyServices.$each.$iter[_index].order.required"
                    class="error">
                    Order is required.
                  </div>
                  <div
                    v-if="$v.$error & !$v.keyServices.$each.$iter[_index].order.numeric"
                    class="error">
                    Only positive number is acceptable.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-30">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                <div class="text-right mt-30">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                            @click="saveKeyServices">
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import { numeric, required } from 'vuelidate/lib/validators'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'
import UtilityMixin from '@/mixins/utility-mixin'

export default {
  name: 'AllCountryKeyServiceForm',
  mixins: [FormMixin, validationMixin, UtilityMixin],
  data () {
    return {
      keyServices: [
        {
          icon: {
            previewUrl: null,
            file: null,
            name: null,
            tsync_id: null
          },
          subtitle: '',
          order: 0
        }
      ],
      photoTsyncIdMapping: {},
      photoPreviewMapping: {}
    }
  },
  validations: {
    keyServices: {
      required,
      $each: {
        order: {
          required,
          numeric
        },
        subtitle: {
          required
        },
        icon: {
          tsync_id: {
            required
          }
        }
      }
    }
  },
  computed: {
    keyServicesContentsState () {
      return this.$store.state.EnablingFarmer.enablingFarmersContents
    },
    keyServicesContents () {
      return this.$store.getters.getEnablingFarmersAllCountryKeyServices
    }
  },
  methods: {
    addMoreKeyServices () {
      this.keyServices.push({
        icon: {
          previewUrl: null,
          file: null,
          name: null,
          tsync_id: null
        },
        subtitle: '',
        order: 1
      })
    },
    onIconChange (e, index) {
      // index => key statistics array objects index
      if (!this.keyServices[index].icon) {
        this.keyServices[index].icon = {}
      }
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.keyServices[index].icon.previewUrl = URL.createObjectURL(file)
        this.keyServices[index].icon.file = file
        this.keyServices[index].icon.name = file.name
        this.keyServices[index].icon.tsync_id = this.uuidV4()
      } else {
        // if icon upload widget clicked and no image selected
        this.keyServices[index].icon.previewUrl = null
        this.keyServices[index].icon.file = null
        this.keyServices[index].icon.name = null
        this.keyServices[index].icon.tsync_id = null
      }
      this.$forceUpdate()
    },
    async saveKeyServices () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const keyStatsPromises = []
          this.keyServices.map((item) => {
            const response = this.submitEachKeyServices(item)
            keyStatsPromises.push(response)
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(keyStatsPromises).then((values) => {
            const photoPromises = []
            values.map((item) => {
              const iconId = item.data.icon ? item.data.icon.id : 0
              const iconTsyncId = item.data.icon ? item.data.icon.tsync_id : ''
              if (iconId === 0 || iconTsyncId === '') return
              const photoFile = this.photoTsyncIdMapping[iconTsyncId]
              if (!photoFile) return
              const response = this.uploadMultipartPhoto(iconId, photoFile)
              photoPromises.push(response)
            })
            Promise.all(photoPromises).then((iconResponses) => {
              this.$notify({
                title: 'Key Services',
                text: 'Key services updated successfully.',
                type: 'success', // 'warn', 'error', 'success'
                duration: 5000
              })
              this.$forceUpdate()
            })
          })
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachKeyServices (data) {
      const formData = this.$_.cloneDeep(data)
      const iconTsyncId = (formData.icon && formData.icon.tsync_id) ? formData.icon.tsync_id : this.uuidV4()
      this.photoTsyncIdMapping[iconTsyncId] = data.icon.file
      if (data.icon && data.icon.previewUrl) {
        this.photoPreviewMapping[iconTsyncId] = data.icon.previewUrl
      }
      if (!formData.id) {
        formData.icon = {
          tsync_id: iconTsyncId,
          extension: formData.icon.name.split('.').pop()
        }
      } else {
        try {
          formData.icon.extension = formData.icon.name.split('.').pop()
        } catch (e) {

        }
      }
      formData.content = this.$store.getters.getContentByAppModule(Menus.enablingFarmers.key).id
      formData.content_type = ContentTypes.MINI_CARD
      formData.parent = this.$store.getters.getParentContentItemByContentType(
        ContentTypes.KEY_SERVICE, Menus.enablingFarmers.key).id
      return await this.$store.dispatch('createOrUpdateEnablingFarmersContent', formData)
    }
  },
  watch: {
    keyServicesContents: {
      deep: true,
      handler (newValue) {
        if (newValue.length !== 0) {
          this.keyServices = _.cloneDeep(newValue)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-preview {
  height: 42px;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
