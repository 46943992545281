<template>
  <div>
    <div class="bg-white py-40">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="form-group">
            <div class="form-row">
              <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                <label class="col-form-label">Bar Chart Title</label>
              </div>
              <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                <input v-model="chartForm.title" class="admin-input form-control"
                       placeholder="Type bar chart vertical data type like “Year”"
                       type="text">
                <div
                  v-if="$v.$error & !$v.chartForm.title.required"
                  class="error">
                  Chart title is required.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-row">
              <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                <label class="col-form-label">Bar chart horizontal data type</label>
              </div>
              <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                <input v-model="chartForm.chartHorizontalData" class="admin-input form-control"
                       placeholder="Type bar chart horizontal data type like “Number of farmer”"
                       type="text">
                <div
                  v-if="$v.$error & !$v.chartForm.chartHorizontalData.required"
                  class="error">
                  Horizontal data title is required.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mt-30">
            <div class="form-group">
              <div class="form-row">
                <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                  <label class="col-form-label">Bar chart vertical data type</label>
                </div>
                <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                  <input v-model="chartForm.chartVerticalData" class="admin-input form-control"
                         placeholder="Type bar chart vertical data type like “Year”"
                         type="text">
                  <div
                    v-if="$v.$error & !$v.chartForm.chartVerticalData.required"
                    class="error">
                    Vertical data title is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-30">
              <div class="form-row">
                <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                  <label class="col-form-label">File</label>
                </div>
                <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                  <!-- Start: Drag & Drop Upload Form -->
                  <div class="col-md-8 col-lg-12 p-0 col-sm-12">
                    <div class="form-group">
                      <div class="dashed_upload">
                        <div class="wrapper">
                          <div class="drop drop-inline drop-sm">
                            <div class="cont">
                              <img alt=""
                                   src="@/assets/img/icons/upload1.svg">
                              <div v-if="!chartForm.file.tsync_id" class="desc"> Upload Excel File</div>
                              <div v-if="chartForm.file.tsync_id" class="desc">{{ chartForm.file.name }}</div>
                            </div>
                            <input type="file"
                                   @change="onExcelChange($event)">
                            <div
                              v-if="$v.$error & !$v.chartForm.file.tsync_id.required"
                              class="error">
                              An excel file is required.
                            </div>
                          </div>
                          <div class="d-inline-flex float-right">
                            <button
                              v-show="chartForm.file.tsync_id"
                              class="btn btn-add-success float-right" @click="previewChart">
                              Chart Preview
                            </button>
                          </div>
                          <div class="d-inline-flex">
                            <i v-show="chartForm.file.tsync_id" aria-hidden="true"
                               class="fa fa-times ml-2 clear-file"
                               @click="clearFile(chartForm)"> Clear</i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End: Drag & Drop Upload Form -->
                </div>
              </div>
            </div>
            <div class="form-group mt-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Select color for the country</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <div class="d-flex">
                    <div v-for="(country, _index) in countries" :key="_index" class="colorpicker-item">
                      <input :value="getCountryColor(country.public_code, _index)" class="colorpicker" type="color"
                             @change="pickCountryColor($event, country.public_code, _index)">
                      <span>{{ country.name }}</span>
                    </div>
                  </div>
                </div>
                <!-- End: Drag & Drop Upload Form -->
              </div>
            </div>
            <div class="form-row ">
              <div class="col-md-12 m-auto">
                <div class="text-right mt-30">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                            @click="saveChartData">
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="isChartPreviewed && this.chartForm.file.name" class="form-row highchart mt-50 px-90">
        <div class="col">
          <ChartReport :chart-content="chartForm"></ChartReport>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import xlsx from 'xlsx'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'
import UtilityMixin from '@/mixins/utility-mixin'
import ChartReport from '@/components/admin/enabling-farmres/ChartReport'
import _ from 'lodash'

export default {
  name: 'AllCountryChartForm',
  components: {
    ChartReport
  },
  data () {
    return {
      chartForm: {
        title: '',
        chartHorizontalData: '',
        chartVerticalData: '',
        file: {
          file: null,
          name: null,
          tsync_id: null
        },
        series: [],
        categories: []
      },
      chartColorScheme: ['#006330', '#69aa1a', '#ed1c24', '#636466', '#66a183', '#a5cd8f', '#f4777c', '#999999', '#bfd8cb', '#d6ebcf', '#fbc6c8', '#cccccc'],
      isChartPreviewed: false,
      fileTsyncIdMapping: {}
    }
  },
  computed: {
    countries () {
      return _.sortBy(this.$store.getters.getNkgCountry, 'name')
    },
    chartFormContents () {
      return this.$store.getters.getEnablingFarmersAllCountryChartContent
    }
  },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  validations: {
    chartForm: {
      title: {
        required
      },
      chartHorizontalData: {
        required
      },
      chartVerticalData: {
        required
      },
      file: {
        tsync_id: { required }
      }
    }
  },
  methods: {
    pickCountryColor (e, countryCode, index) {
      if (this.chartColorScheme.length) {
        this.chartColorScheme[index] = e.target.value
      } else {
        this.chartColorScheme.push(e.target.value)
      }
    },
    getCountryColor (countryCode, index) {
      return this.chartColorScheme[index] || '#000000'
    },
    onExcelChange (e) {
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.chartForm.file.file = file
        this.chartForm.file.name = file.name
        this.chartForm.file.tsync_id = this.uuidV4()

        this.prepareChartData()
      } else {
        // if excel upload widget clicked and no file selected
        this.chartForm.file.file = null
        this.chartForm.file.name = null
        this.isChartPreviewed = false
        this.chartForm.file.tsync_id = null
        // clear chart data
        this.chartContent = {}
      }
    },
    previewChart () {
      this.isChartPreviewed = true
      this.prepareChartData()
    },
    prepareChartData () {
      var countryNames = this.countries.map(function (item) {
        return { ...item }
      })
      if (this.chartForm.file.file) {
        const file = this.chartForm.file.file
        this.chartForm.series = []
        this.chartForm.categories = []
        const fileReader = new FileReader()
        fileReader.onload = ev => {
          try {
            const data = ev.target.result
            const XLSX = xlsx
            const workbook = XLSX.read(data, {
              type: 'binary'
            })
            const wsname = workbook.SheetNames[0] // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
            const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])
            const firstColHeader = Object.keys(ws[0])[0]

            const excelData = [] // Clear received data
            //
            const wsLength = ws.length
            for (var i = 0; i < wsLength; i++) {
              excelData.push(ws[i])
            }

            this.chartForm.categories = excelData.map((d) => {
              return d[firstColHeader]
            })
            this.chartForm.chart_horizontal_data = this.chartForm.chartHorizontalData
            this.chartForm.chart_vertical_data = this.chartForm.chartVerticalData

            const objectKeys = Object.keys(excelData[0])
            _.remove(objectKeys, function (n) {
              return n === firstColHeader
            })
            for (const [key, value] of Object.entries(objectKeys)) {
              const countryObject = _.filter(countryNames, ['public_code', value])
              const countryName = countryObject.length ? countryObject[0].name : ''
              const countryCode = countryObject.length ? countryObject[0].public_code : ''
              if (!countryName) {
                continue
              }
              this.chartForm.series.push({
                name: countryName,
                color: this.chartColorScheme[key],
                data: excelData.map((d) => {
                  return d[countryCode]
                })
              })
            }
          } catch (e) {
            return console.log('File can\'t read!')
          }
        }
        fileReader.readAsBinaryString(file)
      } else {
        const chartSeries = this.chartForm.series
        for (const [key, value] of Object.entries(chartSeries)) {
          value.color = this.chartColorScheme[key] || '#000000'
          this.chartForm.series[key].color = this.chartColorScheme[key] || '#000000'
        }
      }
    },
    async saveChartData () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const chartPromises = []
          const response = this.submitChartContent(this.chartForm)
          chartPromises.push(response)
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(chartPromises).then((values) => {
            const filePromises = []
            values.map((item) => {
              const fileId = item.data.file ? item.data.file.id : 0
              const fileTsyncId = item.data.file ? item.data.file.tsync_id : ''
              if (fileId === 0 || fileTsyncId === '') return
              const file = this.fileTsyncIdMapping[fileTsyncId]
              if (!file) return
              const response = this.uploadFile(fileId, file)
              filePromises.push(response)
            })
            Promise.all(filePromises).then((fileResponses) => {
              this.$notify({
                title: 'Chart',
                text: 'Chart updated successfully.',
                type: 'success', // 'warn', 'error', 'success'
                duration: 5000
              })
              this.$forceUpdate()
            })
          })
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitChartContent (data) {
      const formData = this.$_.cloneDeep(data)
      const fileTsyncId = (formData.file && formData.file.tsync_id) ? formData.file.tsync_id : this.uuidV4()
      this.fileTsyncIdMapping[fileTsyncId] = data.file.file
      if (this.chartForm.file.file) {
        if (!formData.id) {
          formData.file = {
            tsync_id: fileTsyncId,
            extension: formData.file.name.split('.').pop()
          }
        }
      }
      formData.content = this.$store.getters.getContentByAppModule(Menus.enablingFarmers.key).id
      formData.content_type = ContentTypes.CHART
      formData.title = this.chartForm.title
      formData.chart_horizontal_data = this.chartForm.chartHorizontalData
      formData.chart_vertical_data = this.chartForm.chartVerticalData
      formData.chart_options = this.chartColorScheme
      formData.chart_data = {
        categories: this.chartForm.categories,
        series: this.chartForm.series
      }
      return await this.$store.dispatch('createOrUpdateEnablingFarmersContent', formData)
    }
  },
  watch: {
    chartFormContents: {
      deep: true,
      handler (newValue) {
        if (!this.$_.isEmpty(newValue)) {
          // this.chartForm = _.cloneDeep(newValue)
          this.chartForm.id = newValue.id
          this.chartForm.title = newValue.title
          if (_.get(newValue, 'chart_options') && newValue.chart_options !== '""') {
            this.chartColorScheme = newValue.chart_options ? JSON.parse(newValue.chart_options) : []
          }
          this.chartForm.chart_horizontal_data = newValue.chart_horizontal_data
          this.chartForm.chart_vertical_data = newValue.chart_vertical_data
          this.chartForm.chartHorizontalData = newValue.chart_horizontal_data
          this.chartForm.chartVerticalData = newValue.chart_vertical_data
          if (_.isObject(newValue.chart_data)) {
            this.chartForm.series = newValue.chart_data ? newValue.chart_data.series : []
            this.chartForm.categories = newValue.chart_data ? newValue.chart_data.categories : []
          } else {
            this.chartForm.series = newValue.chart_data ? JSON.parse(newValue.chart_data).series : []
            this.chartForm.categories = newValue.chart_data ? JSON.parse(newValue.chart_data).categories : []
          }
          this.chartForm.file.tsync_id = newValue.file.tsync_id
          this.chartForm.file.name = newValue.file.name
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.clear-file {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
