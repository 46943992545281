<template>
  <div>
    <all-country-greetings></all-country-greetings>
    <div class="bg-white py-40">
      <AllCountryGreetingsAndChartForm></AllCountryGreetingsAndChartForm>
    </div>
    <AllCountryKeyStatisticsForm></AllCountryKeyStatisticsForm>
    <AllCountryKeyServiceGreetings></AllCountryKeyServiceGreetings>
    <AllCountryKeyServiceForm></AllCountryKeyServiceForm>
  </div>
</template>

<script>
import AllCountryGreetingsAndChartForm from '@/components/admin/enabling-farmres/all-country/AllCountryChartForm'
import AllCountryKeyStatisticsForm from '@/components/admin/enabling-farmres/all-country/AllCountryKeyStatisticsForm'
import AllCountryKeyServiceForm from '@/components/admin/enabling-farmres/all-country/AllCountryKeyServiceForm'
import AllCountryGreetings from '@/components/admin/enabling-farmres/all-country/AllCountryGreetings'
import AllCountryKeyServiceGreetings
  from '@/components/admin/enabling-farmres/all-country/AllCountryKeyServiceGreetings'

export default {
  name: 'AllCountry',
  components: {
    AllCountryKeyServiceForm,
    AllCountryKeyStatisticsForm,
    AllCountryGreetingsAndChartForm,
    AllCountryGreetings,
    AllCountryKeyServiceGreetings
  }
}
</script>

<style scoped>

</style>
